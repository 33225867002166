import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from 'oidc-react';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import enLocale from 'date-fns/locale/en-GB';
import { useCookies } from 'react-cookie';
import {
  AppsSection,
  Notification,
  NotificationsMenuParameters,
  NotificationStatus,
} from '@eposnow/ui-core';
import { apiFetchDoNotUse as apiFetchFn, ErrorObject } from '../api/fetch';
import { UIContext } from './UIContext';
import localesMap from '../i18n/localesMap.json';
import { SubuserRightsResponse, UserAccessRights, User } from '../types';
import ErrorLoadingData from '../components/ErrorLoadingData';
import AuthenticatingSpinner from '../components/AuthenticatingSpinner';
import { NOTIFICATIONS_PER_PAGE } from '../constants';

const DEFAULT_LOCALE = 'en-GB';

export type UserRole = {
  RoleId: string;
  RoleName: string;
  Description: string;
};

export type AppsMenuResponse = {
  baseUrl: string;
  items: AppsSection[][];
};

export const userRoles = {
  moduleStandalone: '_EposStandalonePayments',
  moduleNewOnboardingUrl: '_EposNowNewOnboardingURL',
};

/* eslint-enable @typescript-eslint/no-empty-function */
const UserContext = React.createContext({
  apiFetch: apiFetchFn,
  locale: DEFAULT_LOCALE,
  localeFns: enLocale,
  userCountry: undefined,
  isLoadingUserCountry: true,
  user: undefined,
  retryUserCountry: () => {
    /* no op */
  },
  userModules: [] as UserRole[] | undefined,
  userAccessRights: {} as UserAccessRights,
  cdnUrl: '',
  appsMenu: [] as AppsSection[][],
  isLoadingUserApps: false,
  isErrorUserApps: false,
  refetchUserApps: () => {
    /* no op */
  },
  notificationsMenuParameters: {} as NotificationsMenuParameters,
});

export const isInRole = (userModules: UserRole[] | undefined, module: string) =>
  userModules?.filter((el) => el.RoleName === module).length > 0;

const UserContextProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);
  const [localeFns, setLocaleFns] = useState<Locale>(enLocale);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [userCountry, setUserCountry] = useState('');
  const [cookies, setCookie] = useCookies(['useDarkTheme', 'disableAnimation']);
  const [cdnUrl, setCdnUrl] = useState('');
  const [appsMenu, setAppsMenu] = useState<AppsSection[][]>([]);

  const queryClient = useQueryClient();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationsUnreadCount, setNotificationsUnreadCount] = useState<number>(0);

  const { setDisableAnimationFromBoolean, setColorModeFromBoolean } = useContext(UIContext);

  const [searchParams, _setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('disableNav'))
      sessionStorage.setItem('disableNav', searchParams.get('disableNav'));
  }, []);

  const apiFetch = (
    url: string,
    v4Errors = true,
    body = {},
    method = 'GET',
    authorization = '',
    retry = true,
    plain = false,
    extraHeaders: Record<string, string> = {}
  ) =>
    apiFetchFn(
      url,
      v4Errors,
      body,
      method,
      `Bearer ${auth?.userData?.access_token}`,
      retry,
      plain,
      extraHeaders
    );

  const importLocaleFile = async (lang: string) => {
    const localeToSet = await import(`date-fns/locale/${lang}/index.js`);
    setLocaleFns(localeToSet.default);
  };

  const changeLanguage = (language: string) => {
    let lang = language;
    let dateLang = language;
    if (localesMap.locales[lang]) {
      dateLang = localesMap.locales[lang];
    } else {
      lang = DEFAULT_LOCALE;
      dateLang = DEFAULT_LOCALE;
    }

    setLocale(lang);
    i18n.changeLanguage(lang).then(() => {
      importLocaleFile(dateLang).then();
    });
    document.documentElement.setAttribute('lang', lang);
  };

  const {
    refetch: fetchData,
    data: user,
    isLoading,
    isError,
    error,
  } = useQuery('user', () => apiFetch(`${process.env.REACT_APP_API_IDENTITY}v1/user`), {
    enabled: queryEnabled,
    staleTime: 0,
    onSuccess: (data: User) => {
      changeLanguage(data.locale);
      Sentry.setTag('ui_language', data.locale);
      Sentry.setTag('user_id', data.id);
    },
  });

  const { refetch: retryUserCountry, isLoading: isLoadingUserCountry } = useQuery(
    'territory',
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_PAYMENTS}v1/payments/company-info/get-company-region`,
        true,
        {},
        'GET',
        '',
        true,
        true
      ),
    {
      enabled: queryEnabled,
      onSuccess: (data: string) => {
        setUserCountry(data);
      },
    }
  );

  const { data: userModules } = useQuery(
    'userModules',
    () => apiFetch(`${process.env.REACT_APP_API_V4}user/modules`),
    {
      enabled: queryEnabled,
      onSuccess: (data: UserRole[]) => {
        Sentry.setTag('standalone_user', isInRole(data, userRoles.moduleStandalone));
      },
    }
  );

  const {
    isLoading: isLoadingUserApps,
    refetch: refetchUserApps,
    isError: isErrorUserApps,
  } = useQuery(
    'userApps',
    () =>
      apiFetch(`${process.env.REACT_APP_API_IDENTITY}v1/apps`, true, {}, 'GET', '', true, false, {
        'Accept-Language': user.locale,
      }),
    {
      enabled: queryEnabled && !!user?.locale,
      onSuccess: (data: AppsMenuResponse) => {
        setCdnUrl(data.baseUrl);
        setAppsMenu(data.items);
      },
    }
  );

  const {
    isError: isErrorUserNotifications,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingNotifications,
    refetch: refetchUserNotifications,
  } = useInfiniteQuery(
    'userNotifications',
    async ({ pageParam = 1 }) => {
      const pagination = createSearchParams({
        page: String(pageParam),
        limit: String(NOTIFICATIONS_PER_PAGE),
      });
      return apiFetch(
        `${process.env.REACT_APP_API_IDENTITY}v1/notification/in-app-notifications${
          pagination ? `?${pagination}` : ''
        }`
      );
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.metadata.totalPages >= nextPage ? nextPage : undefined;
      },
      enabled: queryEnabled && !!user,
      staleTime: 0,
      onSuccess: (response) => {
        const lastPage = response.pages[response.pages.length - 1];
        const allNotifications = response.pages.reduce<Notification[]>(
          (acc, page) => acc.concat(page.data),
          []
        );
        setNotifications(allNotifications);
        setNotificationsUnreadCount(lastPage.metadata.adHocMetadata.unreadRecords);
      },
      onError: (err: ErrorObject) => {
        if (err?.code === 401) return;
        Sentry.captureException(new Error(`Error loading notifications`), {
          extra: { error: JSON.stringify(err) },
        });
      },
    }
  );

  const onLoadMoreNotificationsAsync = useCallback(async () => {
    if (hasNextPage) await fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  const { mutateAsync: onReadNotification } = useMutation(
    async ({ notification }: { notification: Notification }) => {
      const response = await apiFetch(
        `${process.env.REACT_APP_API_IDENTITY}v1/notification/in-app-notifications/${notification.notificationId}`,
        true,
        [
          {
            op: 'replace',
            path: '/status',
            value: NotificationStatus.Read,
          },
        ],
        'PATCH'
      );

      return response;
    },
    {
      onError: (err: ErrorObject) => {
        if (err?.code === 401) return;
        Sentry.captureException(new Error(`Error reading notification`), {
          extra: { error: JSON.stringify(err) },
        });
      },
    }
  );

  const onReadNotificationAsync = useCallback(
    async (notification: Notification) => {
      if (notification.status !== NotificationStatus.Created) return;

      // This update is necessary to immediately reflect the change in the notification's status
      // within the React Query cache without waiting for a refetch. When a notification is marked
      // as "Read", we need to update the corresponding notification in our cached "userNotifications"
      // data to ensure the UI displays the correct state. Additionally, we decrement the unread
      // notifications count (ensuring it doesn’t go below zero) in the metadata. This way, both the
      // list of notifications and the unread count stay in sync with the user's action.
      queryClient.setQueryData('userNotifications', (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: any) => ({
          ...page,
          data: page.data.map((n: Notification) =>
            n.notificationId === notification.notificationId
              ? { ...n, status: NotificationStatus.Read }
              : n
          ),
          metadata: {
            ...page.metadata,
            adHocMetadata: {
              ...page.metadata.adHocMetadata,
              unreadRecords: Math.max(page.metadata.adHocMetadata.unreadRecords - 1, 0),
            },
          },
        })),
      }));

      await onReadNotification({ notification });
    },
    [onReadNotification]
  );

  const { mutateAsync: onDeleteNotification } = useMutation(
    async ({ notification }: { notification: Notification }) => {
      const response = await apiFetch(
        `${process.env.REACT_APP_API_IDENTITY}v1/notification/in-app-notifications/${notification.notificationId}`,
        true,
        [
          {
            op: 'replace',
            path: '/status',
            value: NotificationStatus.Dismissed,
          },
        ],
        'PATCH'
      );

      return response;
    },
    {
      onError: (err: ErrorObject) => {
        if (err?.code === 401) return;
        Sentry.captureException(new Error(`Error deleting notification`), {
          extra: { error: JSON.stringify(err) },
        });
      },
    }
  );

  const onDeleteNotificationAsync = useCallback(
    async (notification: Notification) => {
      setNotifications((prev) =>
        prev.filter((n) => n.notificationId !== notification.notificationId)
      );

      if (notification.status === NotificationStatus.Created) {
        setNotificationsUnreadCount((prevCount) => Math.max(prevCount - 1, 0));
      }

      await onDeleteNotification({ notification });

      if (hasNextPage) {
        queryClient.removeQueries('userNotifications');
      }
    },
    [hasNextPage, onDeleteNotification]
  );

  const { data: uiPreferences } = useQuery(
    'uiPreferences',
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_ORGANISATIONS}v1/users/${auth?.userData?.profile.sub}/ui-preferences`
      ),
    {
      enabled: queryEnabled && auth?.userData?.profile.sub != null,
      staleTime: 0,
      onSuccess: (data: { useDarkTheme: boolean; disableAnimation: boolean }) => {
        setCookie('useDarkTheme', data?.useDarkTheme);
        setCookie('disableAnimation', data?.disableAnimation);
        setColorModeFromBoolean(data.useDarkTheme);
        setDisableAnimationFromBoolean(data.disableAnimation);
      },
    }
  );

  useEffect(() => {
    setColorModeFromBoolean(uiPreferences?.useDarkTheme || cookies.useDarkTheme);
    setDisableAnimationFromBoolean(uiPreferences?.disableAnimation || cookies.disableAnimation);
  }, [uiPreferences]);

  useEffect(() => {
    let timer;
    if (auth.isLoading) {
      timer = setTimeout(() => {
        if (auth.isLoading) {
          window.location.href = '/';
        }
      }, 3000);
      return () => clearTimeout(timer);
    }

    setQueryEnabled(true);
    return () => clearTimeout(timer);
  }, [auth.isLoading]);
  const { data: userRights, isLoading: isLoadingAccessRights } = useQuery(
    'userRights',
    () => apiFetch(`${process.env.REACT_APP_API_V4}subuser/${auth?.userData?.profile.sub}`),
    {
      enabled:
        !auth?.isLoading &&
        auth?.userData?.profile?.sub?.toLowerCase() !==
          (auth?.userData?.profile?.parent_guid as string)?.toLowerCase(),
    }
  );

  const formatAccessRights = useCallback(
    (isSublogin: boolean, accessRights: SubuserRightsResponse | undefined): UserAccessRights => {
      if (!isSublogin) {
        return {
          Billing: true,
          LocationAreaID: null,
          Setup: true,
          Management: true,
          PurchaseOrderCreate: true,
          PurchaseOrderEditCancel: true,
          PurchaseOrderReceive: true,
          Product: true,
          Reporting: true,
          Margin: true,
          Till: true,
          WebIntegration: true,
          Apps: true,
          GlobalCustomer: true,
          Franchise: true,
        };
      }

      if (!accessRights) {
        return {};
      }

      return {
        Billing: accessRights.BillingRights || false,
        LocationAreaID: accessRights.AccessRights.LocationAreaID,
        Setup: accessRights.AccessRights.Setup || false,
        Management: accessRights.AccessRights.Management || false,
        PurchaseOrderCreate: accessRights.AccessRights.PurchaseOrder.Create || false,
        PurchaseOrderEditCancel: accessRights.AccessRights.PurchaseOrder.EditCancel || false,
        PurchaseOrderReceive: accessRights.AccessRights.PurchaseOrder.Receive || false,
        Product: accessRights.AccessRights.Product || false,
        Reporting: accessRights.AccessRights.Reporting || false,
        Margin: accessRights.AccessRights.Margin || false,
        Till: accessRights.AccessRights.Till || false,
        WebIntegration: accessRights.AccessRights.WebIntegration || false,
        Apps: accessRights.AccessRights.Apps || false,
        GlobalCustomer: accessRights.AccessRights.GlobalCustomer || false,
        Franchise: accessRights.AccessRights.Franchise || false,
      };
    },
    []
  );

  if (window.location.pathname.includes('logged-out')) return null;

  const userAccessRights = formatAccessRights(
    auth?.userData?.profile?.sub?.toLowerCase() !==
      (auth?.userData?.profile?.parent_guid as string)?.toLowerCase(),
    userRights
  );

  const unauthorized = (error as any)?.code === 401;

  if (auth && auth.userData) {
    if (isError && unauthorized) return <AuthenticatingSpinner />;
    if (isError) return <ErrorLoadingData action={fetchData} />;
    if (isLoading || !user)
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '96px',
          }}
        >
          <CircularProgress
            aria-label="Loading User Data"
            aria-live="polite"
            aria-busy={isLoading || !user}
            data-testid="loading-icon"
            sx={{ color: 'text.secondary' }}
          />
        </Box>
      );
    return (
      <UserContext.Provider
        value={{
          apiFetch,
          locale,
          localeFns,
          user,
          userCountry,
          retryUserCountry,
          isLoadingUserCountry,
          userModules,
          userAccessRights,
          isLoadingUserApps,
          cdnUrl,
          appsMenu,
          isErrorUserApps,
          refetchUserApps,
          notificationsMenuParameters: {
            isError: isErrorUserNotifications,
            notifications,
            notificationsUnreadCount,
            isLoadingNotifications,
            hasMoreNotificationsToLoad: hasNextPage,
            onDeleteNotificationAsync,
            onReadNotificationAsync,
            onLoadMoreNotificationsAsync,
            onRetryFetchNotifications: refetchUserNotifications,
          },
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }

  if (auth.isLoading) {
    return <AuthenticatingSpinner />;
  }

  return null;
};

export { UserContext, UserContextProvider };
