import React, { useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ColorPartial, PaletteColorOptions } from '@mui/material/styles/createPalette';
import { Color as muiColor } from '@mui/material';
import { getDesignTokens, setDocumentUiVariables } from '@eposnow/ui-core';
import { UIContext } from './UIContext';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    cardTitle: React.CSSProperties;
    eventTitle: React.CSSProperties;
    textLabel: React.CSSProperties;
    boldText: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    cardTitle?: React.CSSProperties;
    eventTitle?: React.CSSProperties;
    textLabel?: React.CSSProperties;
    boldText?: React.CSSProperties;
  }

  interface SimplePaletteColorOptions {
    // the default values don't need to be added due to module augmentation
    // default contains mandatory: main
    // and optional: light, dark, contrastText
    primary?: string;
    '160p'?: string;
    '190p'?: string;
    '8dp'?: string;
    '12dp'?: string;
  }

  interface PaletteColor {
    // the default values don't need to be added due to module augmentation
    // default contains mandatory: light, main, dark, contrastText
    primary?: string;
    '160p'?: string;
    '190p'?: string;
    '8dp'?: string;
    '12dp'?: string;
  }

  interface TypeText {
    contrast?: string;
  }

  interface Palette {
    main: muiColor;
    elevation: muiColor;
    snackbar: PaletteColor;
  }

  export interface PaletteOptions {
    main?: ColorPartial;
    elevation?: ColorPartial;
    snackbar?: PaletteColorOptions;
  }
}

declare module '@mui/material/index' {
  interface Color {
    '4p'?: string;
    '8p'?: string;
    '12p'?: string;
    '16p'?: string;
    '18p'?: string;
    '23p'?: string;
    '26p'?: string;
    '30p'?: string;
    '50p'?: string;
    '54p'?: string;
    '56p'?: string;
    '65p'?: string;
    '70p'?: string;
    '87p'?: string;
    '0dp'?: string;
    '1dp'?: string;
    '2dp'?: string;
    '4dp'?: string;
    '8dp'?: string;
    '12dp'?: string;
    '16dp'?: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
    eventTitle: true;
    textLabel: true;
    boldText: true;
  }
}

const ThemeContext = ({ children }: { children: React.ReactNode }) => {
  const { colorMode } = useContext(UIContext);
  const designTokens = getDesignTokens(colorMode ?? 'light');
  setDocumentUiVariables(colorMode ?? 'light');

  return <ThemeProvider theme={createTheme(designTokens)}>{children}</ThemeProvider>;
};

export default ThemeContext;
