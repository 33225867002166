const SUCCESS_MESSAGE_TIME = 3000;
const NAV_DRAWER_WIDTH = 296;
const ADDRESS_DRAWER_WIDTH = 320;
const XS_WIDTH = 0;
const SM_WIDTH = 640;
const MD_WIDTH = 1024;
const LG_WIDTH = 1200;
const XL_WIDTH = 1536;
const ADDRESSES_PER_PAGE = 3;
const ADDRESSES_PER_LARGE_PAGE = 5;
const NOTIFICATIONS_PER_PAGE = 20;
const ANIMATION_MINIMUM_TIMEOUT = 1000;
const STATE_LIST = {
  AU: [
    "New South Wales",
    "Victoria",
    "Queensland",
    "Tasmania",
    "South Australia",
    "Western Australia",
    "Northern Territory",
    "Australian Capital Territory",
  ],
  CA: [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon Territory",
  ],
  NZ: [
    "Auckland",
    "Bay of Plenty",
    "Canterbury",
    "Chatham Islands Territory",
    "Gisborne",
    "Greater Wellington",
    "Hawke's Bay",
    "Manawatū Whanganui",
    "Marlborough",
    "Nelson",
    "Northland",
    "Otago",
    "Southland",
    "Taranaki",
    "Tasman",
    "Waikato",
    "West Coast",
  ],
  US: [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Federated States Of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Islands",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ]
};

export {
  SUCCESS_MESSAGE_TIME,
  NAV_DRAWER_WIDTH,
  ADDRESS_DRAWER_WIDTH,
  XS_WIDTH,
  SM_WIDTH,
  MD_WIDTH,
  LG_WIDTH,
  XL_WIDTH,
  ADDRESSES_PER_PAGE,
  ADDRESSES_PER_LARGE_PAGE,
  ANIMATION_MINIMUM_TIMEOUT,
  STATE_LIST,
  NOTIFICATIONS_PER_PAGE
};
