import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { BaseTextFieldProps } from '@mui/material/TextField';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import IntlTelInput, { CountryData, IntlTelInputProps } from '@eposnow/react-intl-tel-input';
import '@eposnow/react-intl-tel-input/dist/main.css';

import IntlTelFieldUtils from './IntlTelFieldUtils';
import NotchedOutline from './NotchedOutline';

export type BaseIntlTelFieldProps = Pick<
  BaseTextFieldProps,
  | 'color'
  | 'error'
  | 'FormHelperTextProps'
  | 'helperText'
  | 'InputLabelProps'
  | 'label'
  | 'required'
  | 'size'
  | 'variant'
>;

const IntlTelField = React.forwardRef<IntlTelInput, IntlTelInputProps & BaseIntlTelFieldProps>(
  (props, ref) => {
    const {
      color = 'primary',
      error,
      FormHelperTextProps,
      helperText,
      InputLabelProps,
      label,
      required,
      size,
      variant = 'outlined',
      ...intlTelInputProps
    } = props;

    const {
      disabled,
      fieldId: id,
      containerClassName: containerCss = '',
      inputClassName: inputCss = '',
    } = intlTelInputProps;

    const [filled, setFilled] = useState(false);
    const [focused, setFocused] = useState(false);

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;

    // HACK: Append the NotchedOutline into the IntlTelInput container tree.
    //
    // Due to the lack of composition support in IntlTelInput for suffixed elements,
    // the NotchedOutline has to be created outside of the IntlTelInput hierarchy.
    // When the component mounts, nest the NotchedOutline into IntlTelInput accordingly.
    // The result is visually identical to the official MUI version.
    //
    // RISK: React will fail if the NotchedOutline's fieldset HTML element is removed.

    // #region Outline hack
    const telInputRef = useRef<HTMLInputElement | null | undefined>(undefined);
    const intlTelInputRef = useRef<IntlTelInput | null>(null);

    const fieldsetClassName = clsx('MuiOutlinedIntlTelInput-notchedOutline');
    const fieldsetRef = useRef<HTMLFieldSetElement | null>(null);
    const setIntlTelInputRef = (instance: IntlTelInput | null) => {
      intlTelInputRef.current = instance;
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref && 'current' in ref) {
        ref.current = instance; // eslint-disable-line no-param-reassign
      }
    };

    useEffect(() => {
      setFilled(!!intlTelInputProps.value);
    }, [intlTelInputProps.value]);

    useEffect(() => {
      setFilled(!!intlTelInputProps.value);
      telInputRef.current = intlTelInputRef.current?.tel;
      IntlTelFieldUtils.insertAfter(telInputRef.current, fieldsetRef.current);
    }, []);
    // #endregion

    const handlePhoneNumberFocus = (
      isValid: boolean,
      value: string,
      selectedCountryData: CountryData,
      fullNumber: string,
      extension: string,
      event: React.FocusEvent<HTMLInputElement>
    ) => {
      setFocused(true);

      if (!props.onPhoneNumberFocus) {
        return;
      }

      props.onPhoneNumberFocus(isValid, value, selectedCountryData, fullNumber, extension, event);
    };
    const handlePhoneNumberBlur = (
      isValid: boolean,
      value: string,
      selectedCountryData: CountryData,
      fullNumber: string,
      extension: string,
      event: React.FocusEvent<HTMLInputElement>
    ) => {
      setFocused(false);

      if (!props.onPhoneNumberBlur) {
        return;
      }

      props.onPhoneNumberBlur(isValid, value, selectedCountryData, fullNumber, extension, event);
    };

    const handlePhoneNumberChange = (
      isValid: boolean,
      value: string,
      selectedCountryData: CountryData,
      fullNumber: string,
      extension: string
    ) => {
      setFilled(!!value);
      if (!props.onPhoneNumberChange) {
        return;
      }

      props.onPhoneNumberChange(isValid, value, selectedCountryData, fullNumber, extension);
    };

    const Variant = IntlTelFieldUtils.capitalizeFirstLetter(variant);
    const Color = IntlTelFieldUtils.capitalizeFirstLetter(color);
    const formControlClassName = clsx('MuiTextField-root', 'MuiIntlTelField-root');
    const containerClassName = clsx(
      'intl-tel-input',
      `Mui${Variant}Input-root`,
      `Mui${Variant}IntlTelInput-root`, // Custom style
      'MuiInputBase-root',
      `MuiInputBase-color${Color}`,
      {
        'Mui-focused': focused || filled,
      },
      'MuiInputBase-formControl',
      containerCss // Consumer overrides
    );
    const inputClassName = clsx(
      'form-control',
      `Mui${Variant}Input-input`,
      `Mui${Variant}IntlTelInput-input`, // Custom style
      'MuiInputBase-input',
      inputCss // Consumer overrides
    );

    return (
      <FormControl
        className={formControlClassName}
        disabled={disabled}
        error={error}
        focused={focused || filled}
        required={required}
        size={size}
        sx={{
          marginBottom: 3,
          width: '100%',
          '.MuiIntlTelField-root .MuiFormLabel-filled': {
            maxWidth: 'calc(133% - 24px)',
          },
          '.MuiFormLabel-root.MuiInputLabel-root': {
            left: '40px',
            transform: 'translate(14px, 16px) scale(1)',
          },
          '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
            left: 0,
            transform: 'translate(14px, -9px) scale(0.75)',
            ...(!focused &&
              !error && {
                color: 'text.secondary',
              }),
          },
          '.MuiOutlinedIntlTelInput-input': {
            font: 'inherit',
            letterSpacing: 'inherit',
            color: 'text.primary',
            border: 0,
            boxSizing: 'content-box',
            background: 'none',
            height: '1.4375em',
            margin: 0,
            WebkitTapHighlightColor: 'transparent',
            display: 'block',
            minWidth: 0,
            width: '100%',
            WebkitAnimationName: 'mui-auto-fill-cancel',
            animationName: 'mui-auto-fill-cancel',
            WebkitAnimationDuration: '10ms',
            animationDuration: '10ms',
            paddingTop: '1px',
            padding: '16.5px 14px',
          },
          '.MuiOutlinedIntlTelInput-input:focus': {
            outline: 0,
          },
          '.MuiOutlinedIntlTelInput-root:hover .MuiOutlinedIntlTelInput-notchedOutline': {
            borderColor: `${error ? 'error.main' : 'grey.23p'}`,
          },
          '.MuiOutlinedIntlTelInput-root.Mui-focused .MuiOutlinedIntlTelInput-notchedOutline': {
            borderWidth: `${focused ? '2px' : '1px'}`,
            borderColor: 'grey.23p',
            ...(focused && {
              borderColor: 'primary.main',
            }),
            ...(error && {
              borderColor: 'error.main',
            }),
          },
          '.country-list': {
            width: '100%',
            backgroundColor: 'popoverMenuBackground',
          },
          '.country-list .country': {
            color: 'text.primary',
          },
          '.MuiOutlinedIntlTelInput-notchedOutline': {
            textAlign: 'left',
            position: 'absolute',
            bottom: 0,
            right: 0,
            top: '-5px',
            left: 0,
            margin: 0,
            padding: '0 8px',
            pointerEvents: 'none',
            borderRadius: 'inherit',
            borderStyle: 'solid',
            borderWidth: '1px',
            overflow: 'hidden',
            minWidth: '0%',
            borderColor: `${error ? '#D32F2F' : 'rgba(0, 0, 0, 0.23)'}`,
          },
          '.MuiOutlinedIntlTelInput-notchedOutline legend': {
            display: 'block',
            width: 'auto',
            padding: '0',
            height: '11px',
            fontSize: '0.75em',
            visibility: 'hidden',
            maxWidth: '0.01px',
            transition: 'max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          },
          '.MuiOutlinedIntlTelInput-notchedOutline legend > span': {
            paddingLeft: '5px',
            paddingRight: '10px',
            display: 'inline-block',
          },
          '.MuiOutlinedIntlTelInput-root.Mui-filled fieldset legend, .MuiOutlinedIntlTelInput-root.Mui-focused fieldset legend':
            {
              display: 'block',
              width: 'auto',
              padding: '0',
              height: '11px',
              fontSize: '0.75em',
              visibility: 'hidden',
              maxWidth: '100%',
              transition: 'max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms',
            },
        }}
      >
        {label && (
          <InputLabel {...InputLabelProps} htmlFor={id} id={inputLabelId}>
            {label}
          </InputLabel>
        )}

        <IntlTelInput
          {...(intlTelInputProps as IntlTelInputProps)}
          onPhoneNumberFocus={handlePhoneNumberFocus}
          onPhoneNumberBlur={handlePhoneNumberBlur}
          onPhoneNumberChange={handlePhoneNumberChange}
          ref={setIntlTelInputRef}
          containerClassName={containerClassName}
          inputClassName={inputClassName}
          style={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
            color: 'text.primary',
            boxSizing: 'border-box',
            position: 'relative',
            cursor: 'text',
            display: 'inline-flex',
            alignItems: 'center',
            borderRadius: '4px',
          }}
        />
        <NotchedOutline
          aria-hidden
          className={fieldsetClassName}
          ref={fieldsetRef}
          label={required ? `${label}*` : label}
        />
        {helperText && (
          <FormHelperText id={helperTextId} {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default IntlTelField;
