import { Breadcrumb, BreadcrumbHint } from "@sentry/react";

const breadcrumbHook = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => ({
    ...breadcrumb,
    data: {
        ...breadcrumb.data,
        from: breadcrumb.data && breadcrumb.data.from && breadcrumb.data.from.includes("/?code=") ? "<oauth callback>" : breadcrumb.data?.from
    }
});

export default breadcrumbHook;
