import React from 'react';
import ReactDOM from 'react-dom/client';
import reactAxe from '@axe-core/react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import reportWebVitals from './reportWebVitals';
import worker from './mocks/browser';
import breadcrumbHook from './helpers/sentryHelpers';
import 'overlayscrollbars/styles/overlayscrollbars.css';

if (process.env.REACT_APP_MSW_ENABLED === 'true') {
  worker.start();
}

if (process.env.REACT_APP_AXE_ENABLED === 'true') {
  reactAxe(React, ReactDOM, 1000);
}

if (
  process.env.REACT_APP_SENTRY_ENABLED === 'true' &&
  process.env.REACT_APP_ENVIRONMENT !== 'localhost'
) {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: 'https://05a43aa24a8c462da7c8d7f0159776ba@sentry.eposnow.io/68',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
    profilesSampleRate: 0.25,
    release: `onboarding@${process.env.REACT_APP_VERSION}`,
    beforeBreadcrumb: breadcrumbHook,
  });
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
