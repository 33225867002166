import { setupWorker } from 'msw';
// import stepsHandlers from './handlers/stepsHandlers';
// import countryHandlers from './handlers/countryHandlers';
// import addressHandlers from './handlers/addressHandlers';
// import kycHandlers from './handlers/kycHandlers';
// import userHandlers from './handlers/userHandlers';
// import themeHandlers from './handlers/themeHandlers';
// import territoryHandlers from './handlers/territoryHandlers';
// import companyInfoHandlers from './handlers/companyInfoHandlers';
// import industryTypesHandlers from './handlers/industryTypesHandlers';
// import payoutSettingsHandlers from './handlers/payoutSettingsHandlers';
// import appsMenuHandlers from './handlers/appsMenuHandlers';
// import notificationsMenuHandlers from './handlers/notificationsMenuHandlers';

const handlers = [
  // ...userHandlers,
  // ...stepsHandlers,
  // ...themeHandlers,
  // ...addressHandlers,
  // ...countryHandlers,
  // ...companyInfoHandlers,
  // ...industryTypesHandlers,
  // ...payoutSettingsHandlers,
  // ...kycHandlers,
  // ...territoryHandlers,
  // ...appsMenuHandlers,
  // ...notificationsMenuHandlers
];

const server = setupWorker(...handlers);

export default server;
