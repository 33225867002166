/* eslint-disable react/button-has-type */
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Box, Container } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { AuthProvider } from 'oidc-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './i18n/i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThemeContext from './context/ThemeContext';
import AppRoutes from './AppRoutes';
import Maintenance from './Maintenance';
import { UIContextProvider } from './context/UIContext';
import Fallback from './components/Fallback';
import { UserContextProvider } from './context/UserContext';
import LoggedOut from './screens/loggedOut/LoggedOut';
import BannerContextProvider from './context/BannerContext';
import Banners from './components/Banners';
import NavigationComponents from './components/NavigationComponents';
import { getBaseDomain } from './helpers/helpers';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 2 * 60 * 1000,
    },
  },
});

const baseDomain = getBaseDomain(window.location.href);
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const validRoutes = ['/tutorials'];

const App = () => {
  const maintenanceMode = process.env.REACT_APP_MAINTENANCE_ENABLED === 'true';

  const oidcConfig = {
    scope: process.env.REACT_APP_OAUTH_SCOPE,
    authority: process.env.REACT_APP_OAUTH_HOST,
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirectUri: process.env.REACT_APP_OAUTH_CALLBACK_URL,
    loadUserInfo: false,
    onBeforeSignIn: () => {
      if (
        validRoutes.some((route) => window.location.pathname.includes(route)) &&
        !sessionStorage.getItem('redirect_pathname')
      ) {
        sessionStorage.setItem('redirect_pathname', window.location.pathname);
      }
      return ''; // this function expects a string
    },
    onSignIn: () => {
      if (sessionStorage.getItem('redirect_pathname')) {
        window.location.href = sessionStorage.getItem('redirect_pathname');
        sessionStorage.removeItem('redirect_pathname');
      }
    },
    onSignOut: () => {
      localStorage.clear();
      sessionStorage.clear();
    },
  };

  return (
    <ErrorBoundary fallback={<Fallback />}>
      {!maintenanceMode ? (
        <CookiesProvider defaultSetOptions={{ domain: `.${baseDomain}` }}>
          <UIContextProvider>
            <ThemeContext>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <SentryRoutes>
                    <Route path="/" element={null} />
                    <Route path="/logged-out" element={<LoggedOut />} />
                  </SentryRoutes>
                </Router>
                <AuthProvider {...oidcConfig}>
                  <BannerContextProvider>
                    <Router>
                      <UserContextProvider>
                        <Box
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <ErrorBoundary
                            beforeCapture={(scope) => {
                              scope.setTag('location', 'TopNav');
                            }}
                            fallback={<Fallback />}
                          >
                            <NavigationComponents />
                          </ErrorBoundary>
                          <Container
                            component="main"
                            id="main"
                            sx={{
                              margin: 'auto',
                              paddingBottom: 12,
                              paddingX: { xs: 2, sm: 3 },
                              paddingTop: { xs: 10, sm: 11 },
                              flexGrow: 1,
                            }}
                          >
                            <Banners />
                            <AppRoutes />
                          </Container>
                        </Box>
                      </UserContextProvider>
                    </Router>
                  </BannerContextProvider>
                </AuthProvider>
              </QueryClientProvider>
            </ThemeContext>
          </UIContextProvider>
        </CookiesProvider>
      ) : (
        <Maintenance />
      )}
    </ErrorBoundary>
  );
};

export default App;
