import { KYCResponseType } from '../../types';

// unless it's explicitly set to true, assume false by default
const checkKYCPassed = (kycResponse: KYCResponseType, isMyBusinessAccount = false) => {
  // if the user has an external bank account, the "HasPassedKyc" flag is enough
  // to validate whether KYC has passed
  if (!isMyBusinessAccount) return kycResponse?.HasPassedKyc;

  // if the user has an EposNow bank account, we can't use "HasPassedKyc" since that
  // requires capabilities that the user doesn't have. For that reason we use another 
  // check, based on data from the kycResponse variable
  return (
    kycResponse?.ReceiveFromBalanceAccount &&
    kycResponse?.ReceiveFromPlatformPayments &&
    kycResponse?.SendToBalanceAccount &&
    kycResponse?.ReceivePayments
  );
};

export default checkKYCPassed;

