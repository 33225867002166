import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import NotFound from './screens/error/NotFound';
import Addresses from './steps/addresses/Addresses';
import StepController from './components/StepController';
import BusinessInfo from './steps/businessInfo/BusinessInfo';
import Complete from './steps/complete/Complete';
import Callback from './screens/callback/Callback';
import Verification from './steps/verification/Verification';
import Maintenance from './screens/maintenance/Maintenance';
import NoSteps from './screens/noSteps/NoSteps';
import Tutorials from './steps/tutorials/Tutorials';
import saturnProPlusSteps from './steps/config/tutorials/saturnProPlusSteps';
import saturnProSteps from './steps/config/tutorials/saturnProSteps';
import proSteps from './steps/config/tutorials/ProV400';
import linkSteps from './steps/config/tutorials/LinkP400';
import ams1StandaloneSteps from './steps/config/tutorials/Ams1Standalone';
import ams1ProSteps from './steps/config/tutorials/Ams1Pro';
import TutorialStepController from './components/TutorialStepController';
import PayoutSettings from './steps/paymentsConfiguration/PayoutSettings';
import BankAccount from './steps/bankAccount/BankAccount';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const AppRoutes = () => (
  <SentryRoutes>
    <Route element={<StepController />}>
      <Route path="/" element={null} />
      <Route path="/payments-business" element={<BusinessInfo />} />
      <Route path="/payments-location" element={<Addresses />}>
        <Route path=":addressId" element={null} />
      </Route>
      <Route path="/payments-complete" element={<Complete />} />
      <Route path="/payments-verification" element={<Verification />} />
      <Route path="/payments-configuration" element={<PayoutSettings />} />
      <Route path="/payments-bank-account" element={<BankAccount />} />
      <Route path="/complete" element={<Complete />} />
    </Route>
    <Route path="/tutorials" element={<Tutorials />} />
    {[
      saturnProPlusSteps,
      saturnProSteps,
      proSteps,
      linkSteps,
      ams1StandaloneSteps,
      ams1ProSteps,
    ].map((config) => (
      <Route key={config.header.title} element={<TutorialStepController stepConfig={config} />}>
        {config.stepper.map(({ stepKey, stepOrder, component }) => (
          <Route key={stepOrder} path={stepKey} element={component} />
        ))}
      </Route>
    ))}
    <Route path="/no-steps" element={<NoSteps />} />
    <Route path="*" element={<NotFound />} />
    <Route path="/maintenance" element={<Maintenance />} />
    <Route path="/callback" element={<Callback />} />
  </SentryRoutes>
);
export default AppRoutes;
