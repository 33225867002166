export type CompanyInfoForm = {
  organisationId: string;
  organisationName: string;
  industryType: string;
  startTradingDate: string;
};

export type CompanyInfo = {
  organisationId: string;
  organisationName: string;
  industryType: number | null;
  startTradingDate: string | null;
  businessType?: number;
  firstName?: string;
  lastName?: string;
  issueBankAccount?: boolean;
};
export type IndustryType = {
  IndustryTypeId: number;
  Name: string;
};

export type KYCResponseType = {
  HasPassedKyc: boolean;
  CompanyId: string;
  ReceiveFromBalanceAccount: boolean;
  ReceiveFromPlatformPayments: boolean;
  ReceiveFromTransferInstrument: boolean;
  SendToBalanceAccount: boolean;
  SendToTransferInstrument: boolean;
  IssueBankAccount: boolean;
  ReceiveFromThirdParty: boolean;
  SendToThirdParty: boolean;
  ReceivePayments: boolean;
  ReceiveGrants: boolean;
  GetGrantOffers: boolean;
  MigrationInProgress: boolean;
  IssueCardCommercial: boolean;
  UseCardCommercial: boolean;
  status?: number;
};

export type KycResult = {
  hasPassed: boolean;
  hasStartedKyc: boolean;
};

export type PaymentsUrls = {
  OnboardingUrl: string;
};

export type HOPUrls = {
  Url: string;
};

export type PayoutSettingsType = {
  PayoutDelayDays: number;
  PayoutTime: string | null;
  Timezone: string | null;
  UtcOffset: number;
  ValidPayoutTimes?: string[] | null;
  OfferSameDayPayouts?: boolean;
};

export const defaultPayoutSettings: PayoutSettingsType = {
  PayoutTime: '',
  UtcOffset: 0,
  PayoutDelayDays: 1,
  Timezone: null,
  OfferSameDayPayouts: false,
};

export type Step = {
  stepKey: string;
  stepOrder: number;
  startedAt?: string;
  completedAt?: string;
  skippedAt?: string;
};

export type CurrentStep = {
  completable: boolean;
  error: string;
};

export type Term = {
  termType: string;
  acceptedAt: string | null;
};

export type TermUpdate = {
  termType: string;
};

export type User = {
  id: string;
  fullName: string;
  userName: string;
  email: string;
  location: string;
  locale: string;
  isEmailVerified: boolean;
  isTwoFactorEnabled: boolean;
};

export type UserInfoFormatted = {
  fullName: string;
  email: string;
  companyGuid: string;
  uiLanguage: string;
};

export const businessTypes = [
  'soleTrader',
  'ltd',
  'cic',
  'nfp',
  'partnership',
  'llc',
  'ccorp',
  'scorp',
  'organisation',
];
export const PRELIM_COUNTRIES = ['GB'];

export type UserRole = {
  RoleId: string;
  RoleName: string;
  Description: string;
};

export type SubuserRightsResponse = {
  UserVarsId: number;
  UserId: string;
  ParentId: string;
  SubLoginName: string;
  Email: string;
  LoginChallengeEmailEnabled: boolean;
  LoginChallengeEmailAddress: string | null;
  EnabledFlag: boolean;
  BillingRights: boolean;
  AccessRights: {
    LocationAreaID: number | null;
    Setup: boolean;
    Management: boolean;
    PurchaseOrder: {
      Create: boolean;
      EditCancel: boolean;
      Receive: boolean;
    };
    Product: boolean;
    Reporting: boolean;
    Margin: boolean;
    Till: boolean;
    WebIntegration: boolean;
    Apps: boolean;
    GlobalCustomer: boolean;
    Franchise: boolean;
  };
};

export type AccessRights =
  | 'Billing'
  | 'LocationAreaID'
  | 'Setup'
  | 'Management'
  | 'PurchaseOrderCreate'
  | 'PurchaseOrderEditCancel'
  | 'PurchaseOrderReceive'
  | 'Product'
  | 'Reporting'
  | 'Margin'
  | 'Till'
  | 'WebIntegration'
  | 'Apps'
  | 'GlobalCustomer'
  | 'Franchise';
export type UserAccessRights = {
  Billing?: boolean;
  LocationAreaID?: number | null;
  Setup?: boolean;
  Management?: boolean;
  PurchaseOrderCreate?: boolean;
  PurchaseOrderEditCancel?: boolean;
  PurchaseOrderReceive?: boolean;
  Product?: boolean;
  Reporting?: boolean;
  Margin?: boolean;
  Till?: boolean;
  WebIntegration?: boolean;
  Apps?: boolean;
  GlobalCustomer?: boolean;
  Franchise?: boolean;
};
